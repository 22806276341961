import React from "react";
import { Row } from "../../components/common/Row";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { getAnalytics, logEvent } from "firebase/analytics";
import { constants } from "@chatforce/common";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Card } from "../../components/common/elements/Card";

export const TenantSelectScreen = () => {
  const [tenantKey, setTenantKey] = React.useState<string>("");
  const [tenantKeyError, setTenantKeyError] = React.useState<boolean>(false);
  const host = window.location.host;
  const protocol = window.location.protocol;
  const analytics = getAnalytics();

  const hasError = (): boolean => {
    return tenantKeyError;
  };
  const onClickSubmit = async () => {
    logEvent(analytics, "clicked_submit_subdomain_button");
    if (tenantKey === "") {
      return;
    }
    window.location.href = `${protocol}//${tenantKey}.my.${host}/login`;
  };
  return (
    <ResponsiveCol className="login-box flex justify-center items-center h-full">
      <Card className="border p-10 min-w-[400px] flex items-center">
        <Row>
          <h2 className="mb-8">
            サブドメイン入力
          </h2>
        </Row>
        <Row className="flex items-center">
          <p>{`${protocol}//`}</p>
          <ValidatedTextField
            type="text"
            watchError={setTenantKeyError}
            value={tenantKey}
            onChange={(e) => {
              setTenantKey(e.target.value);
            }}
            required={true}
            maxLength={constants.MAX_TENANT_KEY_LENGTH}
            className="bg-white rounded w-[300px]"
          />
          <p>.my.{host}</p>
        </Row>
        <button
          onClick={onClickSubmit}
          className="btn btn-neutral self-center mt-6"
          disabled={hasError()}
        >
          ログイン画面へ
        </button>
        <p className="mt-8 text-center">
          ※サブドメイン名がわからない場合は管理者にお問い合わせください。
        </p>
      </Card>
    </ResponsiveCol>
  );
};
