import { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useMediaQuery } from "../../utils/tailwindUtils";
import { Col } from "../../components/common/Col";
import { DataSource } from "./DataSource";
import { TextPreprocessing, TextProcessingConfig } from "./TextPreprocessing";
import { KnowledgeCreationComplete } from "./Complete";
import { atom, RecoilRoot } from "recoil";

export const uploadedFileState = atom<File | null>({
    key: "knowledgeUploadedFile",
    default: null,
})

export const preprocessConfigState = atom<TextProcessingConfig | null>({
    key: "knowledgePreprocessConfig",
    default: null,
})

const DrawerContent = (props: { close: () => void }) => {
    return (
        <Col
            className="h-full self-center bg-zinc-50"
            role="presentation"
        >
            <div className="flex flex-1" />
        </Col>
    );
}

export const KnowledgeLayout = () => {
    const [step, setStep] = useState(0); // ステップを管理するstateを追加

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isMedium = useMediaQuery("md");
    const isDrawerDisplayed = false; // TODO: Layoutを調整したあと、KnowledgeLayoutのdrawerを表示する
    const drawerWidth = isDrawerDisplayed ? "240px" : "0px";

    const analytics = getAnalytics();
    const toggleDrawer = (open: boolean) => {
        setIsDrawerOpen(open);
        logEvent(analytics, "toggled_drawer", { open: open });
    };

    const getStepIndex = () => step;

    const renderStepContent = () => {
        switch (step) {
            case 0:
                return <DataSource setStep={setStep} />;
            case 1:
                return <TextPreprocessing setStep={setStep} />;
            case 2:
                return <KnowledgeCreationComplete setStep={setStep} />;
            default:
                return <DataSource setStep={setStep} />;
        }
    };

    return (<RecoilRoot>
        <div className={`drawer h-full w-full ${isDrawerDisplayed ? "drawer-open" : ""}`}>
            <input id="menu-drawer" type="checkbox" className="drawer-toggle" />
            <div className="drawer-side">
                <div className={`min-h-full h-full bg-zinc-50 w-[${drawerWidth}] max-w-[${drawerWidth}] min-w-[${drawerWidth}] fixed z-10`}>
                    <DrawerContent
                        close={() => {
                            toggleDrawer(false);
                        }}
                    />
                </div>
            </div>
            <Col className={`drawer-content main-container w-full h-full p-4 items-start ${isDrawerDisplayed ? "pl-60" : ""}`}>
                <div className="steps w-full mb-4">
                    <div className={`step ${getStepIndex() >= 0 ? "step-primary" : ""}`} onClick={() => setStep(0)}>データソースの選択</div>
                    <div className={`step ${getStepIndex() >= 1 ? "step-primary" : ""}`} onClick={() => setStep(1)}>前処理の設定</div>
                    <div className={`step ${getStepIndex() >= 2 ? "step-primary" : ""}`} onClick={() => setStep(2)}>作成完了</div>
                </div>
                {renderStepContent()}
            </Col>
        </div>
    </RecoilRoot>
    );
};
