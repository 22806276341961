import { ResponsiveCol } from "../../../components/common/ResponsiveCol";

import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import {
  AuditLog,
  ListAuditLogsResponse,
} from "../../../buf/chatforce/admin/v1/admin_pb";
import { useCustomPagination } from "../../../models/hooks/hooks";
import { getLastMonths } from "../../../utils/dateUtils";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export const AuditLogs = () => {
  const adminClient = AdminApiClient.getInstance();
  const lastTwelveMonths = getLastMonths(12);

  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [selectedMonth, setSelectedMonth] = useState(lastTwelveMonths[0]);
  let { page, pageCount, paginatedData, showPagination, handlePageChange } =
    useCustomPagination({ items: auditLogs });
  pageCount = 1000;
  showPagination = true;

  const handleChangeMonth = (value: string) => {
    const currentMonthObj = lastTwelveMonths.find(
      (item) => item.yearMonthStr === value,
    );
    if (currentMonthObj) setSelectedMonth(currentMonthObj);
  };

  useEffect(() => {
    const startDate = new Date(selectedMonth.date);
    startDate.setDate(1);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      1,
    );
    endDate.setDate(0);
    endDate.setHours(23, 59, 59, 999);

    adminClient
      .listAuditLogs({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      })
      .then((res: ListAuditLogsResponse) => {
        setAuditLogs(res.auditLogs);
      });
  }, [selectedMonth]);

  return (
    <ResponsiveCol className="h-full w-auto block">
      <article className="prose">
        <div className="flex-1 w-full">
          <h2 className="mb-8">
            監査ログ
          </h2>
          <div className="dropdown">
            <div role="button" tabIndex={0} className="btn m-1">
              {selectedMonth.yearMonthStr}
              <ChevronDownIcon className="w-4 h-4 text-gray-500" />
            </div>
            <ul tabIndex={0} className="dropdown-content menu shadow bg-base-100 rounded-box z-[1] w-52">
              {lastTwelveMonths.map((item) => (
                <li className="m-0" key={item.yearMonthStr}
                  onClick={(e) => {
                    e.preventDefault();
                    handleChangeMonth(item.yearMonthStr);
                  }}
                >
                  <a>
                    {item.yearMonthStr}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="card border flex w-full items-start overflow-x-scroll my-4">
            <table className="table w-full m-0">
              <thead>
                <tr>
                  <th className="whitespace-nowrap overflow-hidden text-ellipsis">表示名</th>
                  <th className="whitespace-nowrap overflow-hidden text-ellipsis">ユーザーID</th>
                  <th className="whitespace-nowrap overflow-hidden text-ellipsis">タイムスタンプ</th>
                  <th className="whitespace-nowrap overflow-hidden text-ellipsis">メールアドレス</th>
                  <th className="whitespace-nowrap overflow-hidden text-ellipsis">アクション</th>
                  <th className="whitespace-nowrap overflow-hidden text-ellipsis">IPアドレス</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((auditLog) => {
                  return (<tr key={auditLog.id}>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis  max-w-[150px]">
                      {auditLog.displayName}
                    </td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis  max-w-[150px]">
                      {auditLog.uid}
                    </td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis">
                      {auditLog.timestamp
                        ? DateTime.fromJSDate(
                          auditLog.timestamp.toDate(),
                        ).toISO({
                          suppressMilliseconds: true,
                        })
                        : ""}
                    </td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">
                      {auditLog.email}
                    </td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">
                      {auditLog.action}
                    </td>
                    <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">
                      {auditLog.ipAddress}
                    </td>
                  </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {showPagination && (
            <div className="flex justify-end mt-4">
              <div className="btn-group">
                <button className="btn btn-ghost" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                  «
                </button>
                {[...Array(pageCount)].map((_, index) => {
                  const pageIndex = index + 1;
                  if (pageIndex >= page - 3 && pageIndex <= page + 3) {
                    return (
                      <button
                        key={index}
                        className={`btn btn-ghost ${page === pageIndex ? "btn-active" : ""}`}
                        onClick={() => handlePageChange(pageIndex)}
                      >
                        {pageIndex}
                      </button>
                    );
                  }
                  return null;
                })}
                <button className="btn btn-ghost" onClick={() => handlePageChange(page + 1)} disabled={page === pageCount}>
                  »
                </button>
              </div>
            </div>
          )}
        </div>
      </article >
    </ResponsiveCol >
  );
};
