import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { FileUpload } from "./FileUpload/FileUpload";
import { useEffect, useState } from "react";
import { ConfirmationDialog } from "../../components/common/elements/ConfirmationDialog";
import { formatFileSize } from "../../utils/formatFileSize";
import { firestoreDao } from "@chatforce/common";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { StorageService } from "../../models/services/storageService";
import { useRecoilValueLoadable } from "recoil";
import { currentTenantUserQuery } from "../../AppStates";
import { DateTime } from "luxon";
import { useCustomPagination } from "../../models/hooks/hooks";

const tableCellStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export const FileManagement = () => {
  const userClient = UserApiClient.getInstance();
  const storageService = StorageService.getInstance();

  const tenantUserState = useRecoilValueLoadable(currentTenantUserQuery);
  const tenantId = tenantUserState.contents.tenantId;

  const [fileMetas, setFileMetas] = useState<firestoreDao.FileMetaDao[]>([]);
  const [deletingFileMetaId, setDeletingFileMetaId] = useState<string | null>(
    null,
  );
  const { page, pageCount, paginatedData, showPagination, handlePageChange } =
    useCustomPagination({ items: fileMetas });

  const fileInfo = fileMetas.map((item) => ({
    id: item.id,
    fileName: item.fileName,
  }));

  const totalFileSize = fileMetas.reduce((acc, file) => {
    return acc + file.size;
  }, 0);

  const onClickDeleteFile = (fileMetaId: string) => {
    setDeletingFileMetaId(fileMetaId);
  };

  const handleDeleteFile = async (fileMetaId: string) => {
    const deletingFileMeta = fileMetas.find((item) => item.id === fileMetaId);
    if (deletingFileMeta === undefined) return;

    await userClient.deleteFileMeta({ id: fileMetaId });
    await storageService.deleteFile(
      tenantId,
      `uploads/${deletingFileMeta.fileName}`,
    );

    setFileMetas((prev) => prev.filter((item) => item.id !== fileMetaId));
    setDeletingFileMetaId(null);
  };

  useEffect(() => {
    userClient.getFileMetas().then((res) => {
      const metaOfFiles: firestoreDao.FileMetaDao[] = res.fileMetas.map((item) => ({
        id: item.id,
        createdAt: item.createdAt,
        fileName: item.fileName,
        size: item.size,
        type: item.type,
      }));
      setFileMetas(metaOfFiles);
    });
  }, []);

  return (
    <>
      <ResponsiveCol className="h-full">
        <article className="prose w-full">
          <div className="w-full">
            <h2 className="mb-10">
              ファイル管理
            </h2>

            <h4>ファイルリスト</h4>
            <div className="card border w-full items-start overflow-x-scroll">
              <table className="table w-full m-0">
                <thead>
                  <tr>
                    <th className="whitespace-nowrap overflow-hidden text-ellipsis">ファイル名</th>
                    <th className="whitespace-nowrap overflow-hidden text-ellipsis">更新日</th>
                    <th className="whitespace-nowrap overflow-hidden text-ellipsis">サイズ</th>
                    <th className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px]"></th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.map((file, index) => {
                    return (
                      <tr key={index}>
                        <td className="whitespace-nowrap overflow-hidden text-ellipsis">
                          {file.fileName}
                        </td>
                        <td className="whitespace-nowrap overflow-hidden text-ellipsis">
                          {file.createdAt
                            ? DateTime.fromJSDate(file.createdAt.toDate()).toFormat("yyyy年MM月dd日")
                            : ""}
                        </td>
                        <td className="whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]">
                          {formatFileSize(file.size)}
                        </td>
                        <td className="text-end w-[100px]">
                          <button className="btn btn-error" onClick={() => onClickDeleteFile(file.id)}>
                            削除
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {showPagination && (
              <div className="flex justify-end mt-4">
                <div className="btn-group">
                  {Array.from({ length: pageCount }, (_, i) => (
                    <button
                      key={i}
                      className={`btn ${page === i + 1 ? "btn-active" : ""}`}
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <FileUpload
              setFileMetas={setFileMetas}
              className="mt-10"
              uploadedFileInfo={fileInfo}
            />
          </div>
          <h4>トータルサイズ: {formatFileSize(totalFileSize)}</h4>
        </article>
      </ResponsiveCol>
      <ConfirmationDialog
        open={deletingFileMetaId !== null}
        title={"ファイルの削除"}
        body={"ファイルを削除しますか？"}
        buttonColor={"btn-error"}
        buttonText={"削除する"}
        onConfirm={async () => {
          if (deletingFileMetaId === null) return;
          await handleDeleteFile(deletingFileMetaId);
        }}
        onClose={() => setDeletingFileMetaId(null)}
      />
    </>
  );
};
