import { Col } from "../../components/common/Col";
import React, { FormEvent, useState } from "react";
import { Row } from "../../components/common/Row";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { currentTenantUserQuery } from "../../AppStates";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { StorageService } from "../../models/services/storageService";
import { UploadableAvatar } from "../../components/profile/UploadableAvatar";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { getAnalytics, logEvent } from "firebase/analytics";
import { constants } from "@chatforce/common";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import { Card } from "../../components/common/elements/Card";
import { useNavigate } from "react-router-dom";
import { roleLabels } from "../../entities/entities";
import { ToastAlert } from "../../components/common/elements/ToastAlert";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

export const AccountScreen = () => {
  const tenantUserState = useRecoilValue(currentTenantUserQuery);
  const [iconFileState, setIconFileState] = React.useState<File | undefined>(
    undefined,
  );
  const [displayNameState, setDisplayNameState] = React.useState<string>(
    tenantUserState?.userDisplayName ?? "",
  );
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [displayNameInputError, setDisplayNameInputError] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [openSaveToast, setOpenSaveToast] = useState<boolean>(false);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const refresher = useRecoilRefresher_UNSTABLE(currentTenantUserQuery);

  if (!tenantUserState?.uid || !tenantUserState.tenantId) {
    return <UnexpectedErrorScreen />;
  }

  const hasError = (): boolean => {
    return displayNameInputError;
  };
  const onChangeDisplayName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayNameState(event.target.value);
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    logEvent(analytics, "clicked_save_user_profile_button");
    setErrorMessage(undefined);
    setButtonState("loading");
    try {
      let iconUrl;
      if (iconFileState !== undefined && iconFileState !== null) {
        const storageService = StorageService.getInstance();
        iconUrl = await storageService.uploadFile(
          tenantUserState.tenantId,
          `users/${tenantUserState?.uid}/icon.jpg`,
          iconFileState,
        );
      }
      const userClient = UserApiClient.getInstance();
      await userClient.updateUser(
        tenantUserState.uid,
        displayNameState,
        iconUrl,
      );
      setButtonState("available");
      setOpenSaveToast(true);
      refresher();
    } catch (e) {
      console.error(e);
      setErrorMessage("保存に失敗しました。時間をおいて再度お試しください。");
      setButtonState("available");
    }
    logEvent(analytics, "saved_user_profile");
  };

  const copyToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        console.log("Copied to clipboard: ", text);
      }).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    } else {
      console.error("Clipboard API not supported");
    }
  };

  return (
    <Col className="flex-1 h-full content-center justify-start">
      <ResponsiveCol>
        <Card withBorder filled>
          <h2>
            ユーザー設定
          </h2>
          <form onSubmit={onSubmit}>
            <h4>プロフィール画像</h4>
            <Row>
              <UploadableAvatar
                src={tenantUserState.userIconUrl ?? undefined}
                userId={tenantUserState.uid}
                onUpload={(file) => {
                  setIconFileState(file);
                }}
              ></UploadableAvatar>
            </Row>
            <h4>ユーザー名</h4>
            <Row>
              <ValidatedTextField
                type="text"
                value={displayNameState}
                required={true}
                minLength={constants.MIN_USER_NAME_LENGTH}
                maxLength={constants.MAX_USER_NAME_LENGTH}
                watchError={setDisplayNameInputError}
                onChange={onChangeDisplayName}
                className="w-72"
              />
            </Row>
            <h4>ユーザーID</h4>
            <Row
              className="items-center"
            >

              {tenantUserState.uid}
              <div
                onClick={() => copyToClipboard(tenantUserState.uid)}
                className="btn btn-square ml-2"
              >
                <ClipboardDocumentIcon className="size-6" />
              </div>
            </Row>
            <h4>ロール</h4>
            <Row>{roleLabels[tenantUserState.role] ?? ""}</Row>
            {errorMessage ? (
              <div
                className="mb-4 alert alert-error"
              >
                {errorMessage}
              </div>
            ) : (
              <></>
            )}
            <Row className="mt-10">
              <LoadingButton
                type="submit"
                buttonState={buttonState}
                disabled={hasError()}
              >
                保存
              </LoadingButton>
            </Row>
          </form>
        </Card>
      </ResponsiveCol>
      <ToastAlert
        open={openSaveToast}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSaveToast(false);
        }}
        message="設定変更を保存しました。"
        className="alert-success"
      />
    </Col>
  );
};
