import { useRecoilState } from "recoil";
import { preprocessConfigState, uploadedFileState } from "./KnowledgeRoutes";

export const KnowledgeCreationComplete = ({ setStep }: { setStep: (step: number) => void }) => {
    const [uploadedFile, setUploadedFile] = useRecoilState(uploadedFileState);
    const [preprocessConfig, setPreprocessConfig] = useRecoilState(preprocessConfigState);
    return (
        <div className="p-6  mx-auto bg-white shadow-md rounded-lg">
            <div className="mb-6">
                {/* ヘッダー */}
                <div className="flex items-center space-x-2">
                    <span className="text-2xl">🎉</span>
                    <h2 className="text-xl font-bold">ナレッジが作成されました</h2>
                </div>
            </div>

            {/* ナレッジ名 */}
            <div className="mb-6">
                <h2 className="text-sm font-semibold mb-2">ナレッジ名</h2>
                <input
                    type="text"
                    className="input input-bordered w-full"
                    defaultValue={uploadedFile?.name}
                />
            </div>

            {/* 埋め込みの進捗 */}
            <div className="mb-6">
                <div className="mt-4 flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                        <span className="bg-green-100 text-green-600 p-2 rounded-lg">
                            📄
                        </span>
                        <p className="text-sm font-medium">{uploadedFile?.name}</p>
                    </div>
                    <span className="text-sm text-gray-500">100%</span>
                </div>
            </div>

            {/* その他の設定 */}
            <div className="mb-6">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h3 className="text-sm font-semibold">チャンクの長さ</h3>
                        <p className="text-sm text-gray-500">{preprocessConfig?.maxChunkLength}</p>
                    </div>
                </div>
            </div>

            <div className="flex justify-between">
                <button onClick={() => {
                    setUploadedFile(null);
                    setPreprocessConfig(null);
                    setStep(0)
                }}>最初に戻る</button>
                <button className="btn btn-primary">ドキュメントに移動 →</button>
            </div>
        </div>
    );
};