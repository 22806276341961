import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { preprocessConfigState, uploadedFileState } from "./KnowledgeRoutes";

export interface TextProcessingConfig {
  delimiter: string;
  maxChunkLength: number;
  overlap: number;
  textPreprocessingRule: boolean;
  embeddingModel: string;
  searchType: string;
  topK: number;
  scoreThreshold: number;
}

export const TextPreprocessing = ({ setStep }: { setStep: (step: number) => void }) => {
  const uploadedFile = useRecoilValue(uploadedFileState);
  const [preprocessConfig, setPreprocessConfig] = useRecoilState(preprocessConfigState);

  const [delimiter, setDelimiter] = useState("\\n\\n");
  const [maxChunkLength, setMaxChunkLength] = useState(500);
  const [overlap, setOverlap] = useState(50);
  const [textPreprocessingRule, setTextPreprocessingRule] = useState(false);
  const [embeddingModel, setEmbeddingModel] = useState("text-embedding-3-large");
  const [searchType, setSearchType] = useState("vector-search");
  const [topK, setTopK] = useState(3);
  const [scoreThreshold, setScoreThreshold] = useState(0.5);

  const [previewChunks, setPreviewChunks] = useState<string[]>([]);

  return (
    <div className="flex flex-col p-6 lg:flex-row mx-auto bg-white shadow-md rounded-lg space-y-6 lg:space-y-0 lg:space-x-6">
      {/* 左側: フォーム設定 */}
      <div className="flex-1 bg-gray-50 p-6 rounded-lg border">
        <article className="prose">
          <h2>テキストの前処理とクリーニング</h2>

          {/* チェック設定 */}
          <div className="mb-6">
            <h3>チャンク設定</h3>
            <label className="block">
              <span className="text-sm">セグメント識別子</span>
              <input type="text" value={delimiter} onChange={(e) => setDelimiter(e.target.value)} className="input input-bordered w-full mt-1" />
            </label>
            <label className="block">
              <span className="text-sm">最大チャンク長さ</span>
              <input type="number" value={maxChunkLength} onChange={(e) => setMaxChunkLength(Number(e.target.value))} className="input input-bordered w-full mt-1" />
            </label>
            <label className="block">
              <span className="text-sm">チャンクのオーバーラップ</span>
              <input type="number" value={overlap} onChange={(e) => setOverlap(Number(e.target.value))} className="input input-bordered w-full mt-1" />
            </label>
            <h4>テキストの前処理ルール</h4>
            <label className="flex items-center space-x-2">
              <input type="checkbox" checked={textPreprocessingRule} onChange={(e) => setTextPreprocessingRule(e.target.checked)} className="checkbox checkbox-info" />
              <span>連続する改行、タブ、スペースを取り除く</span>
            </label>
          </div>

          {/* 埋め込みモデル */}
          <div className="mb-6">
            <h3>埋め込みモデル</h3>
            <select value={embeddingModel} onChange={(e) => setEmbeddingModel(e.target.value)} className="select select-bordered w-full">
              <option>text-embedding-3-large</option>
              <option>text-embedding-2-medium</option>
            </select>
          </div>

          {/* 検索設定 */}
          <div className="mb-6">
            <h3>検索設定</h3>
            <div className="space-y-4">
              <label className="flex items-center space-x-3">
                <input type="radio" name="search-setting" checked={searchType === "vector-search"} onChange={() => setSearchType("vector-search")} className="radio radio-info" />
                <span>ベクトル検索</span>
              </label>
              <div className="ml-8 space-y-2">
                <label className="block">
                  <span className="text-sm">トップK</span>
                  <input type="number" value={topK} onChange={(e) => setTopK(Number(e.target.value))} className="input input-bordered w-full mt-1" />
                </label>
                <label className="flex items-center space-x-2">
                  <input type="checkbox" checked={scoreThreshold > 0} onChange={(e) => setScoreThreshold(e.target.checked ? 0.5 : 0)} className="checkbox checkbox-info" />
                  <span>スコア閾値</span>
                  <input type="number" value={scoreThreshold} onChange={(e) => setScoreThreshold(Number(e.target.value))} min="0" max="1" step="0.1" className="input input-bordered w-20" />
                </label>
              </div>
            </div>
          </div>

          {/* ボタン */}
          <div className="flex justify-between items-center mt-6">
            <button className="btn btn-outline"
              onClick={() => setStep(0)}
            >前のステップ</button>
            <button className="btn btn-primary"
              onClick={() => {
                setPreprocessConfig({
                  delimiter,
                  maxChunkLength,
                  overlap,
                  textPreprocessingRule,
                  embeddingModel,
                  searchType,
                  topK,
                  scoreThreshold,
                });
                console.log("Uploading file:", uploadedFile?.name, "with config:", preprocessConfig);
              }}
            >保存して処理</button>
            <button onClick={() => setStep(2)}>次へ</button>
          </div>
        </article>
      </div>

      {/* 右側: プレビュー */}
      <div className="flex-1 bg-gray-50 p-6 rounded-lg border">
        <article className="prose">
          <h2>プレビュー</h2>
          <div className="space-y-2 not-prose">
            {previewChunks.length === 0 ?
              <p>ここにプレビューが表示されます</p> :
              previewChunks.map((chunk, index) => {
                const length = chunk.length;
                return <div key={index} className="p-4 border rounded-lg bg-white prose">
                  <h4>#{index + 1}</h4>
                  <p className="text-sm text-gray-600 mt-2">
                    chunk
                  </p>
                  <span className="text-xs text-gray-400">{length} 文字</span>
                </div>
              })}
          </div>
        </article>
      </div>
    </div>
  );
};
