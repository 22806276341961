import { Card } from "../../components/common/elements/Card";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { FormEvent, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { Row } from "../../components/common/Row";
import { constants } from "@chatforce/common";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTenantId } from "../../models/hooks/hooks";
import { AnonymousApiClient } from "../../models/apiClients/anonymousApiClient";
import { Col } from "../../components/common/Col";
import { UnexpectedErrorScreen } from "../Error/UnexpectedErrorScreen";
import { LoadingScreen } from "../Loading/LoadingScreen";

const UpdateComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Col className="mb-10">パスワードの更新が完了しました。</Col>
      <button className="btn btn-neutral" onClick={() => navigate("/")}>
        トップに戻る
      </button>
    </>
  );
};

const ResetPasswordUpdateContent = (props: {
  setCompleted: (completed: boolean) => void;
}) => {
  const [password, setPassword] = useState<string>("");
  const [passwordInputError, setPasswordInputError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const tenantId = useTenantId();
  console.log("parameters", tenantId, token, email);
  const hasError = (): boolean => {
    return passwordInputError;
  };
  useEffect(() => {
    if (
      tenantId === undefined ||
      tenantId === null ||
      email === null
    ) {
      return;
    }
    const client = AnonymousApiClient.getInstance();
    client.getPasswordResetStatus(tenantId, email).then((isValid) => {
      setIsValid(isValid);
    });
  }, [tenantId, email]);

  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    if (
      tenantId === undefined ||
      tenantId === null ||
      token === null ||
      email === null
    ) {
      return;
    }
    setErrorMessage("");
    setButtonState("loading");
    const auth = getAuth();
    auth.tenantId = tenantId;
    auth.useDeviceLanguage();
    const client = AnonymousApiClient.getInstance();
    await client
      .confirmPasswordReset(tenantId, email, token, password)
      .then(() => {
        props.setCompleted(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("処理に失敗しました。時間をおいて再度お試しください。");
      });
    setButtonState("available");
    props.setCompleted(true);
  };
  if (
    tenantId === null ||
    email === null
  ) {
    console.log(tenantId, email);
    return <UnexpectedErrorScreen />;
  }
  if (tenantId === undefined || isValid === undefined) {
    return <LoadingScreen />;
  }
  if (!isValid) {
    return (
      <ResponsiveCol>
        <Card withBorder filled>
          パスワード再設定のURLが無効です。再度発行してください。
        </Card>
      </ResponsiveCol>
    );
  }
  return (
    <form onSubmit={onSubmit}>
      <ValidatedTextField
        type="password"
        label="パスワード"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        required={true}
        minLength={constants.MIN_PASSWORD_LENGTH}
        maxLength={constants.MAX_PASSWORD_LENGTH}
        pattern={constants.PASSWORD_PATTERN}
        watchError={setPasswordInputError}
        className="bg-white rounded w-100"
      />
      <Row>
        パスワードは{constants.MIN_PASSWORD_LENGTH}文字以上で、大文字・小文字・数字・記号を含む必要があります。
      </Row>
      {!!errorMessage ? (
        <div className="alert alert-error mt-4">
          {errorMessage}
        </div>
      ) : (
        <div></div>
      )}
      <LoadingButton
        type="submit"
        className="self-center mt-6"
        disabled={hasError()}
        buttonState={buttonState}
      >
        パスワード再設定
      </LoadingButton>
    </form>
  );
};
export const ResetPasswordUpdateScreen = () => {
  const [completed, setCompleted] = useState<boolean>(false);
  return (
    <ResponsiveCol className="login-box flex justify-center items-center h-full">
      <Card withBorder filled className="p-10 min-w-[400px] flex items-center">
        <Row className="self-start">
          <h2 className="mb-8">
            パスワード再設定
          </h2>
        </Row>
        {completed ? (
          <UpdateComplete />
        ) : (
          <ResetPasswordUpdateContent setCompleted={setCompleted} />
        )}
      </Card>
    </ResponsiveCol>
  );
};
