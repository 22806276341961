import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useRecoilState } from "recoil";
import { uploadedFileState } from "./KnowledgeRoutes";

export const DataSource = ({ setStep }: { setStep: (step: number) => void }) => {
    const [uploadedFile, setUploadedFile] = useRecoilState(uploadedFileState);
    const [isDragActive, setIsDragActive] = useState(false);
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            setUploadedFile(acceptedFiles[0]);
        }
        setIsDragActive(false);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDragEnter: () => setIsDragActive(true),
        onDragLeave: () => setIsDragActive(false),
        onDropAccepted: () => setIsDragActive(false),
        onDropRejected: () => setIsDragActive(false),
    });

    const handleFileUpload = () => {
        if (uploadedFile) {
            // ファイルアップロードのロジックをここに追加します
            console.log("Uploading file:", uploadedFile);
            // 例: API呼び出しでファイルをアップロードする
        }
    };

    return (
        <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md">
            <article className="prose">
                <h2>データソースの選択</h2>
                <div className="mb-6">
                    <h3 className="text-sm font-medium mb-2">ファイルをアップロード</h3>
                    <div
                        {...getRootProps()}
                        className={`border-2 border-dashed p-4 rounded-lg text-center ${isDragActive ? "border-blue-500 bg-blue-100" : "border-gray-300"}`}
                    >
                        <input {...getInputProps()} className="hidden" />
                        <label className="text-blue-500 underline cursor-pointer">
                            ファイルをドラッグ＆ドロップするか参照
                        </label>
                        <p className="text-gray-400 text-sm mt-2">
                            TXT, MARKDOWN, PDF, HTML, XLSX, XLS, DOCX, CSV, EML, MSG, PPTX, XML, EPUB, PPT, MD, HTMをサポートしています。
                            1つあたりの最大サイズは15MBです。
                        </p>
                    </div>
                </div>
                {uploadedFile && (
                    <p className="text-green-500 mt-2">
                        アップロードされたファイル: {uploadedFile.name}
                    </p>
                )}
                <button className={`btn btn-primary w-full mb-4 ${uploadedFile === null ? "btn-disabled" : ""}`} onClick={() => {
                    handleFileUpload();
                    setStep(1);
                }}>次へ</button>
            </article>
        </div>
    );
};
