import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { ChatFirestoreService } from "../../models/services/chatFirestoreService";
import { useEffect, useState } from "react";
import { firestoreDao } from "@chatforce/common";
import { Row } from "../common/Row";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import {
  chatUsersImgState,
  currentLoginTenantQuery,
  currentTenantUserQuery,
} from "../../AppStates";
import { ComponentPermissionGuard } from "../common/ComponentPermissionGuard";
import { Col } from "../common/Col";
import { getAnalytics, logEvent } from "firebase/analytics";
import { UserAvatar } from "../profile/UserAvatar";
import { ConfirmationDialog } from "../common/elements/ConfirmationDialog";
import { UserApiClient } from "../../models/apiClients/userApiClient";
import { useParams } from "react-router";
import "./DrawerContent.scss";
import { configs } from "../../models/utils/Configs";
import { TenantFirestoreService } from "../../models/services/tenantFirestoreService";
import { Avatars } from "./Avatars";
import { DateTime } from "luxon";
import {
  TrashIcon,
  BuildingOffice2Icon,
  DocumentTextIcon,
  InformationCircleIcon,
  ArrowLeftStartOnRectangleIcon,
  SparklesIcon,
  TableCellsIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

const pageLimit = 30;
const NewChatButton = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <Row className="justify-center items-center px-2.5 pt-2.5">
      <Link to={`/`} style={{ textDecoration: "none", width: "100%" }}>
        <button
          className="btn btn-neutral w-full normal-case"
          onClick={() => {
            logEvent(analytics, "clicked_new_chat_button");
            props.close();
          }}
        >
          New Chat +
        </button>
      </Link>
    </Row>
  );
};

const ConversationDay = (props: { text: string }) => {
  return (
    <Row className="w-full flex items-center gap-2">
      <p
        className="whitespace-nowrap overflow-hidden text-ellipsis text-center text-sm text-gray-500 w-full pt-2 pb-2"
      >
        {props.text}
      </p>
    </Row>
  );
};

const LoadMoreButton = (props: { onClick: () => void }) => {
  return (
    <Row key={"load-more-button"} className="w-full flex items-center gap-2">
      <button
        className="btn btn-neutral w-full normal-case text-gray-500"
        onClick={props.onClick}
      >
        更に読み込む
      </button>
    </Row>
  );
};

const ConversationList = (props: { close: () => void }) => {
  const chatUsersImg = useRecoilValue(chatUsersImgState);
  const setChatUsersImgState = useSetRecoilState(chatUsersImgState);
  const loginTenant = useRecoilValueLoadable(currentLoginTenantQuery);
  const loginUser = useRecoilValueLoadable(currentTenantUserQuery);
  const [conversations, setConversations] = useState<firestoreDao.ConversationDao[]>([]);
  const [isLoadMoreButtonVisible, setIsLoadMoreButtonVisible] =
    useState<boolean>(true);
  const [conversationDays, setConversationDays] = useState<DateTime[]>([]);
  const [deleteDialogConversationId, setDeleteDialogConversationId] = useState<
    string | null
  >(null);
  const analytics = getAnalytics();
  const navigate = useNavigate();
  const userApiClient = UserApiClient.getInstance();
  const { conversationId } = useParams();

  const loadConversations = () => {
    const lastConversationDate =
      conversations.length > 0
        ? conversations[conversations.length - 1].updatedAt
        : undefined;

    if (loginTenant.state !== "hasValue") return;
    const tenantId = loginTenant.contents?.id;
    if (tenantId === undefined) return;
    const userId = loginUser.contents?.uid;
    const role = loginUser.contents?.role;
    if (userId === undefined || role === undefined) return;
    const firestoreClient = ChatFirestoreService.getInstance(tenantId ?? null);
    const subscription = firestoreClient
      .watchConversations(userId, role, lastConversationDate, pageLimit)
      .subscribe({
        next(results) {
          if (results.length < pageLimit) setIsLoadMoreButtonVisible(false);
          setConversations([...conversations, ...results]);
        },
        error(err) {
          console.error("error", err);
        },
        complete() {
          console.log("complete");
        },
      });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    loadConversations();
  }, [loginTenant]);

  useEffect(() => {
    const userIds: string[] = [];
    const tmpConversationSeconds = new Set<number>(); // Using Set to ensure uniqueness
    conversations.forEach((conversation) => {
      // conversation dates
      const updatedAtTimestamp = conversation.updatedAt;
      const updatedAtDateTime = DateTime.fromSeconds(
        updatedAtTimestamp.seconds,
      );
      const updatedAtDay = updatedAtDateTime.startOf("day");
      // Convert DateTime to seconds to ensure uniqueness
      tmpConversationSeconds.add(updatedAtDay.toSeconds());

      // conversation members
      if (conversation.members == null) return;

      const firstUserId = conversation.members[0];
      const secondUserId = conversation.members[1];

      if (firstUserId && userIdNotExist(firstUserId, userIds))
        userIds.push(firstUserId);
      if (secondUserId && userIdNotExist(secondUserId, userIds))
        userIds.push(secondUserId);
    });
    // Sorting the seconds in descending order
    const sortedSeconds = Array.from(tmpConversationSeconds).sort(
      (a, b) => b - a,
    );
    // Converting seconds back to DateTime objects and updating the state
    setConversationDays(sortedSeconds.map((it) => DateTime.fromSeconds(it)));
    if (userIds.length > 0) fetchUserInfo(userIds);
  }, [conversations]);

  const getConversationsByDay = (day: DateTime) => {
    return conversations.filter(
      (it) =>
        it.updatedAt.seconds &&
        DateTime.fromSeconds(it.updatedAt.seconds).startOf("day").equals(day),
    );
  };

  const userIdNotExist = (userId: string, userIds: string[]) => {
    return (
      userIds.findIndex((id) => id === userId) === -1 &&
      chatUsersImg.userId === undefined
    );
  };

  const fetchUserInfo = async (userIds: string[]) => {
    const tenantId = loginTenant.contents?.id;
    if (tenantId === undefined) return;

    const tenantService = TenantFirestoreService.getInstance();
    const users = await tenantService.listTenantUsers(tenantId, userIds);
    setChatUsersImgState(users);
  };

  // undefined: No User
  // null: No image
  // string: user image available
  const getUserImgFromState = (
    userId: string | undefined,
  ): undefined | null | string => {
    if (userId === undefined) return undefined;

    return chatUsersImg[userId];
  };

  return (
    <>
      <ul className="w-full">
        {conversationDays.map((day, dayIndex) => {
          return (
            <span key={dayIndex}>
              <ConversationDay
                key={`conversation-day-${dayIndex}`}
                text={day.toFormat("yyyy/MM/dd")}
              />
              {getConversationsByDay(day).map((conversation, i) => {
                const userImgOne = conversation.members
                  ? getUserImgFromState(conversation.members[0])
                  : undefined;
                const userImgTwo = conversation.members
                  ? getUserImgFromState(conversation.members[1])
                  : undefined;

                const selected = conversationId === conversation.id;
                const isOwner =
                  conversation.ownerId !== undefined &&
                  conversation.ownerId === loginUser.contents?.uid;
                const canDelete =
                  isOwner ||
                  ["superAdmin", "admin"].includes(loginUser.contents?.role);
                return (
                  <Row
                    key={conversation.id}
                    className={`items-center justify-stretch rounded-lg p-2 pr-0 h-10 m-1 ${selected ? "bg-gray-300" : ""
                      } cursor-pointer hover:bg-gray-200`}
                    onClick={() => {
                      navigate(`/c/${conversation.id}`);
                      props.close();
                      logEvent(analytics, "clicked_conversation", {});
                    }}
                  >
                    <Row className="w-full flex items-center gap-2">
                      <Avatars
                        imageOne={userImgOne}
                        uidOne={
                          conversation.members
                            ? conversation.members[0]
                            : undefined
                        }
                        imageTwo={userImgTwo}
                        uidTwo={
                          conversation.members
                            ? conversation.members[1]
                            : undefined
                        }
                      />
                      <p
                        className="whitespace-nowrap overflow-hidden text-ellipsis flex-1"
                      >
                        {conversation.title}
                      </p>
                      {selected && canDelete ? (
                        <button
                          className="btn btn-ghost btn-circle"
                          onClick={() =>
                            setDeleteDialogConversationId(conversation.id)
                          }
                        >
                          <TrashIcon className="size-5" />
                        </button>
                      ) : (
                        <></>
                      )}
                    </Row>
                  </Row>
                );
              })}
            </span>
          );
        })}
        {isLoadMoreButtonVisible && (
          <LoadMoreButton onClick={() => loadConversations()} />
        )}
      </ul>
      <ConfirmationDialog
        open={deleteDialogConversationId !== null}
        title={"会話の削除"}
        body={"会話を削除して良いですか？"}
        buttonColor={"btn-error"}
        buttonText={"削除する"}
        onConfirm={async () => {
          if (deleteDialogConversationId === null) return;
          await userApiClient.deleteConversation(deleteDialogConversationId);
          setDeleteDialogConversationId(null);
        }}
        onClose={() => setDeleteDialogConversationId(null)}
      />
    </>
  );
};

const TenantSettingsItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <li>
      <Link
        className="pl-4"
        to={`/tenant`}
        onClick={() => {
          logEvent(analytics, "clicked_tenant_settings");
          props.close();
        }}
      >
        <BuildingOffice2Icon className="size-5" />
        テナント設定
      </Link>
    </li>
  );
};

const TokenUsageReportItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <li>
      <Link
        className="pl-4"
        to={`/tenant/report`}
        onClick={() => {
          logEvent(analytics, "clicked_token_usage_report");
          props.close();
        }}
      >
        <TableCellsIcon className="size-5" />
        レポート
      </Link>
    </li>
  );
};

const FileManagementItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <li>
      <Link
        className="pl-4"
        to={`/tenant/knowledge`}
        onClick={() => {
          logEvent(analytics, "clicked_file_management");
          props.close();
        }}
      >
        <SparklesIcon className="size-5" />
        ナレッジ
      </Link>
    </li>
  );
};

const AuditLogsItem = (props: { close: () => void }) => {
  const analytics = getAnalytics();
  return (
    <li>
      <Link
        className="pl-4"
        to={`/tenant/audit-logs`}
        onClick={() => {
          logEvent(analytics, "clicked_audit_logs");
          props.close();
        }}
      >
        <DocumentTextIcon className="size-5" />
        監査ログ
      </Link>
    </li>
  );
};

const SettingList = (props: { close: () => void }) => {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const onClickLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        logEvent(analytics, "clicked_logout");
        console.log("successfully signed out");
        props.close();
        navigate("/signedout", { replace: true });
      })
      .catch((error) => {
        console.error("failed to sign out", error);
      });
  };

  const enableFileUpload = configs.features.enableFileUpload;

  return (
    <ul className="menu bg-white w-full rounded-md">
      <li>
        <Link
          to={"account"}
          onClick={() => {
            logEvent(analytics, "clicked_setting");
            props.close();
          }}
        >
          <UserCircleIcon className="size-5" />
          ユーザー設定
        </Link>
      </li>
      <ComponentPermissionGuard
        allowedRoles={["admin", "superAdmin", "serviceAdmin"]}
      >
        <TenantSettingsItem close={props.close} />
      </ComponentPermissionGuard>
      <ComponentPermissionGuard
        allowedRoles={["admin", "superAdmin", "serviceAdmin"]}
      >
        <TokenUsageReportItem close={props.close} />
      </ComponentPermissionGuard>
      {enableFileUpload && (
        <ComponentPermissionGuard allowedRoles={["superAdmin"]}>
          <FileManagementItem close={props.close} />
        </ComponentPermissionGuard>
      )}
      <li>
        <Link
          className="pl-4"
          to={`/about`}
          onClick={() => {
            logEvent(analytics, "clicked_about");
            props.close();
          }}
        >
          <InformationCircleIcon className="size-5" />
          About
        </Link>
      </li>
      <ComponentPermissionGuard allowedRoles={["superAdmin"]}>
        <AuditLogsItem close={props.close} />
      </ComponentPermissionGuard>
      <li>
        <button className="pl-4" onClick={onClickLogout}>
          <ArrowLeftStartOnRectangleIcon className="size-5" />
          ログアウト
        </button>
      </li>
    </ul>
  );
};

export const DrawerContent = (props: { close: () => void }) => {
  return (
    <Col
      className="h-full self-center bg-zinc-50"
      role="presentation"
    >
      <div
        className="sticky w-full top-0 self-end"
      >
        <NewChatButton close={props.close} />
      </div>
      <ConversationList close={props.close} />
      <div className="flex flex-1" />
      <div
        className="sticky w-full bottom-0 p-2"
      >
        <div tabIndex={0} className="collapse glass rounded-md">
          <input type="checkbox" />
          <div className="collapse-title p-2 flex">
            <UserAvatar size={"10"} onClick={() => { }} withProfile={true} />
          </div>
          <div className="collapse-content">
            <SettingList close={props.close} />
          </div>
        </div>
      </div>
    </Col>
  );
};
