import { ResponsiveCol } from "../../../components/common/ResponsiveCol";

import { useEffect, useState } from "react";
import { AdminApiClient } from "../../../models/apiClients/adminApiClient";
import { getLastMonths } from "../../../utils/dateUtils";
import { AiModelLabel } from "../../../utils/constants";
import { AiModel } from "../../../buf/chatforce/user/v1/chat_pb";
import {
  ListTokenUsageDataByYearResponse,
  ListTokenUsageYearsResponse,
  UserMonthlyUsages,
} from "../../../buf/chatforce/admin/v1/admin_pb";
import { useRecoilValueLoadable } from "recoil";
import { currentLoginTenantQuery } from "../../../AppStates";
import { UnexpectedErrorScreen } from "../../Error/UnexpectedErrorScreen";
import { firestoreDao, entities } from "@chatforce/common";
import { Row } from "../../../components/common/Row";

const tableCellNameStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const tableCellValueStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "right",
};

type TokenUsageTableProps = {
  aiModel: AiModel;
  tokenUsageYears: string[];
  tenant: entities.IdTenant,
};

type Month = {
  yearMonthStr: string;
  date: Date;
};

/**
 * Returns the months based on the given year.
 *
 * - When the year is the current year, return the months from January to the current month.
 * - When the year is the previous year, return all months
 * - When the year is a future year, return an empty array.
 */
const getMonthsByYear = (year: string): Month[] => {
  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth() + 1; // 1-12 for the current month

  // check future year
  if (Number(year) > Number(nowYear)) return [];

  // prepare months count for getLastMonths function
  const mcount = 12 * (Number(nowYear) - Number(year)) + nowMonth;
  const lastMonths = getLastMonths(mcount);

  // sort last months to asc
  let tmpMonths: Month[] = [];
  lastMonths.forEach((m) => {
    tmpMonths = [m, ...tmpMonths];
  });

  // filter last months by year
  return [...tmpMonths.filter((_, i) => i < 12 && i < mcount)];
};

const TokenUsageTable: React.FC<TokenUsageTableProps> = ({
  aiModel,
  tokenUsageYears,
  tenant,
}) => {
  const adminClient = AdminApiClient.getInstance();

  const [tokenUsageUsers, setTokenUsageUsers] = useState<UserMonthlyUsages[]>(
    [],
  );
  const [currentMonthlyQuota, setCurrentMonthlyQuota] = useState<number | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<string>(
    String(new Date().getFullYear()),
  );
  const [months, setMonths] = useState<Month[]>([]);

  useEffect(() => {
    if (tokenUsageYears.length > 0 && selectedYear === "") {
      setSelectedYear(tokenUsageYears[0]);
    }
  }, [tokenUsageYears]);

  useEffect(() => {
    adminClient
      .listTokenUsageDataByYear(selectedYear, aiModel)
      .then((res: ListTokenUsageDataByYearResponse) => {
        console.log(res);
        setTokenUsageUsers([...res.userMonthlyUsages]);
        setCurrentMonthlyQuota(res.currentMonthlyTokenQuota);
      });

    setMonths(getMonthsByYear(selectedYear));
  }, [selectedYear]);

  const getTokenCount = (id: string, date: Date) => {
    const user = tokenUsageUsers.find((user) => user.id === id);
    if (!user) return 0;
    const month = user.months.find(
      (month) => month.month === date.getMonth() + 1,
    );
    if (!month) return 0;
    return month.tokens;
  };

  const handleChangeYear = (value: string) => {
    if (selectedYear !== value) setSelectedYear(value);
  };

  return (
    <article className="prose">
      <h4>{AiModelLabel[aiModel]}</h4>
      <Row className="font-bold mr-2">
        <span className="font-bold mr-2">現プランでの月間テナントトークン上限: </span> {currentMonthlyQuota?.toString() ?? "-"}
      </Row>
      <Row>※ご契約中のユーザー（{tenant.seats}名）で共有される合算値です</Row>
      <div className="dropdown mt-4">
        <div role="button" tabIndex={0} className="btn m-1">
          {selectedYear}
        </div>
        <ul tabIndex={0} className="dropdown-content menu shadow bg-base-100 rounded-box z-[1] w-52">
          {tokenUsageYears.map((usageYear) => (
            <li className="m-0" key={usageYear}
              onClick={(e) => {
                e.preventDefault();
                handleChangeYear(usageYear);
              }}
            >
              <a>
                {usageYear}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="card border flex w-full items-start overflow-x-scroll my-4">
        <table className="table w-full m-0">
          <thead>
            <tr>
              <th className="whitespace-nowrap overflow-hidden text-ellipsis"></th>
              {months.map((month, index) => (
                <th
                  key={`month-${index}`}
                  className="font-bold whitespace-nowrap overflow-hidden text-ellipsis text-right"
                >
                  {month.yearMonthStr}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tokenUsageUsers.map((user, index) => (
              <tr key={`table-row-${index}`}>
                <td
                  className="whitespace-nowrap overflow-hidden text-ellipsis"
                >
                  {user.userDisplayName}
                </td>
                {months.map((month, index) => (
                  <td
                    key={`table-row-cell-${index}-${user.id}`}
                    className="whitespace-nowrap overflow-hidden text-ellipsis text-right"
                  >
                    {getTokenCount(user.id, month.date)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </article>
  );
};

export const TokenUsage = () => {
  const tenantLoadable = useRecoilValueLoadable(currentLoginTenantQuery);
  const state = tenantLoadable.state;
  const adminClient = AdminApiClient.getInstance();
  const [tokenUsageYears, setTokenUsageYears] = useState<string[]>([]);

  useEffect(() => {
    adminClient
      .listTokenUsageYears()
      .then((res: ListTokenUsageYearsResponse) => {
        setTokenUsageYears(res.years);
      });
  }, []);
  if (state !== "hasValue") {
    return <div>Loading...</div>;
  }
  const tenant = tenantLoadable.getValue();
  if (tenant === null || tenant === undefined) {
    return <UnexpectedErrorScreen />;
  }

  const aiModels: { [key in firestoreDao.PlanTypeName]: AiModel[] } = {
    "none": [],
    "trial": [AiModel.GPT_4_O_MINI],
    "standard": [AiModel.GPT_4_O_MINI, AiModel.GPT_4_O],
  }
  const tenantAiModels = aiModels[tenant.planType];

  return (
    <ResponsiveCol className="h-full block">
      <article className="prose">
        <div className="flex-1 w-full">
          <h2 className="mb-10">
            トークン利用量レポート
          </h2>
          {Object.values(tenantAiModels)
            .map((model) => {
              return (
                <TokenUsageTable
                  key={`model-${model}`}
                  aiModel={Number(model)}
                  tenant={tenant}
                  tokenUsageYears={tokenUsageYears}
                />
              );
            })}
        </div>
      </article>
    </ResponsiveCol>
  );
};
