import { Card } from "../../components/common/elements/Card";
import { ResponsiveCol } from "../../components/common/ResponsiveCol";
import { FormEvent, useState } from "react";
import { ValidatedTextField } from "../../components/common/elements/ValidatedTextField";
import {
  LoadingButton,
  LoadingButtonState,
} from "../../components/common/elements/LoadingButton";
import { useTenantId } from "../../models/hooks/hooks";
import { AnonymousApiClient } from "../../models/apiClients/anonymousApiClient";
import { useNavigate } from "react-router-dom";
import { Row } from "../../components/common/Row";

const SendComplete = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mb-10">
        パスワード再設定メールを送信しました。
      </div>
      <button className="btn btn-neutral" onClick={() => navigate("/")}>
        トップに戻る
      </button>
    </>
  );
};

const ResetPasswordContent = (props: {
  setCompleted: (complete: boolean) => void;
}) => {
  const [email, setEmail] = useState<string>("");
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [buttonState, setButtonState] =
    useState<LoadingButtonState>("available");
  const tenantId = useTenantId();

  const hasError = (): boolean => {
    return emailInputError;
  };
  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    if (tenantId === undefined || tenantId === null) {
      return;
    }
    setErrorMessage("");
    setButtonState("loading");
    const client = AnonymousApiClient.getInstance();
    await client
      .sendPasswordResetLink(email, tenantId)
      .then(() => {
        props.setCompleted(true);
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("処理に失敗しました。時間をおいて再度お試しください。");
      });
    setButtonState("available");
  };
  return (
    <form onSubmit={onSubmit}>
      <ValidatedTextField
        type="email"
        label="メールアドレス"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        required={true}
        watchError={setEmailInputError}
        className="bg-white rounded w-96"
      />
      {!!errorMessage ? (
        <div className="alert alert-error mt-4">
          {errorMessage}
        </div>
      ) : (
        <div></div>
      )}
      <LoadingButton
        type="submit"
        className="self-center mt-6"
        disabled={hasError()}
        buttonState={buttonState}
      >
        メール送信
      </LoadingButton>
    </form>
  );
};
export const ResetPasswordScreen = () => {
  const [complete, setCompleted] = useState<boolean>(false);
  return (
    <ResponsiveCol
      className="login-box flex justify-center items-center h-full"
    >
      <Card
        withBorder
        filled
        className="p-10 min-w-[400px] flex items-center"
      >
        <Row className="self-start">
          <h2 className="mb-8">
            パスワード再設定メール送信
          </h2>
        </Row>
        {complete ? (
          <SendComplete />
        ) : (
          <ResetPasswordContent setCompleted={setCompleted} />
        )}
      </Card>
    </ResponsiveCol>
  );
};
